<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="promoAndExceptionModal"
      :title="$t($route.name === 'growth-hacker-exception-discount' ? `${type ? type : 'Edit'} Exception Discount` : `${type ? type : 'Edit'} Promo Code`)"
      :buttons-hidden="true">
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required'" :label-placeholder="$t('Code') + '*'" name="code" v-model="data.code" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-5"> 
            <v-select v-validate="'required'" class="w-full" name="scope" v-model="data.scope" :placeholder="$t('Scope')" label="name" :options="scopes"/>
            <span class="text-danger text-sm" v-show="errors.has('scope')">{{ errors.first('scope') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-8">
            <v-select v-validate="'required'" class="w-full" name="subscription" multiple v-model="data.qualifier.subscriptions" :placeholder="$t('Subscription')" label="name" :options="subscriptions"/>
            <span class="text-danger text-sm" v-show="errors.has('subscription')">{{ errors.first('subscription') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-8"> 
            <v-select v-validate="'required'" class="w-full" name="type" v-model="data.unit" :placeholder="$t('Unit')" label="name" :options="typesValue"/>
            <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-8">
            <datepicker v-validate="'required'" name="start date" :disabledDates="disabledStartDates" :placeholder="$t('Start Date') + '*'" v-model="data.limits.start_date"  class="w-full"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('start date')">{{ errors.first('start date') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-8">
            <datepicker v-validate="'required'" name="expiry date" :disabledDates="disabledExpiryDates" :placeholder="$t('Expiry Date') + '*'" v-model="data.limits.expiry_date" class="w-full"></datepicker>
            <span class="text-danger text-sm" v-show="errors.has('expiry date')">{{ errors.first('expiry date') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-5 flex items-center justify-between pl-5">
            <label for="value">{{$t('Value')}}</label>
            <vs-input-number v-if="data.unit && data.unit.value === 'percentage'" :min="0" name="value" :value="0" :max="100" v-model="data.value" class="stock-request-input-number" />
            <vs-input-number v-else :min="0" name="value" :value="0" v-model="data.value" class="stock-request-input-number" />
          </div>
          <div class="vx-col w-1/2 mt-5 self-center px-5">
            <label for="">{{$t('Auto Apply')}}</label>
            <vs-switch class="float-right" v-model="data.auto_apply" vs-icon-off="close" vs-icon-on="done"/>
          </div>
          <div class="vx-col w-full mt-8">
            <vs-textarea no-resize v-validate="'required'" name="description" :placeholder="$t('Description')" rows="3" v-model="data.description" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('description')">{{ errors.first('description') }}</span>
          </div>
        </div>
        <div class="vx-row mt-5 mb-2">
          <div class="vx-col">
            <h4>{{$t('Limitations')}}</h4>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|numeric'" :label-placeholder="$t('Use Limit per Code')" name="limit per code" v-model="data.limits.total_usage_count" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('limit per code')">{{ errors.first('limit per code') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|numeric'" :label-placeholder="$t('Use Limit per Merchant')" name="limit per merchant" v-model="data.limits.per_merchant_usage_count" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('limit per merchant')">{{ errors.first('limit per merchant') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-3">
            <vs-input v-validate="'required|numeric'" :label-placeholder="$t('EGP Limit per Code')" name="EGP limit per code" v-model="data.limits.total_cash_value" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('EGP limit per code')">{{ errors.first('EGP limit per code') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-3">
            <vs-input v-validate="'required|numeric'" :label-placeholder="$t('EGP Limit per Merchant')" name="EGP limit per merchant" v-model="data.limits.per_merchant_cash_value" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('EGP limit per merchant')">{{ errors.first('EGP limit per merchant') }}</span>
          </div>
        </div>
        <div class="vx-row mt-10 mb-2">
          <div class="vx-col">
            <h4>{{$t('Qualifier')}}</h4>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2 mt-5">
            <v-select @search="handleSearch" @keyup.enter.native="handleSearch" search multiple class="w-full" name="merchants" v-model="data.qualifier.merchants" :placeholder="$t('Merchants')" label="name" :options="merchants"/>
            <span class="text-danger text-sm" v-show="errors.has('merchants')">{{ errors.first('merchants') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-5">
            <v-select v-validate="'required'" multiple class="w-full" name="service types" v-model="data.qualifier.service_types" :placeholder="$t('Service Type')" :options="serviceTypes"/>
            <span class="text-danger text-sm" v-show="errors.has('service types')">{{ errors.first('service types') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-8">
            <v-select v-validate="'required'" multiple class="w-full" name="source governorates" v-on:input="data.qualifier.source_governorates ? changeValueSourceGov() : ''" v-model="data.qualifier.source_governorates" :placeholder="$t('Source Governorates')" label="name" :options="governorates"/>
            <span class="text-danger text-sm" v-show="errors.has('source governorates')">{{ errors.first('source governorates') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-8">
            <v-select v-validate="'required'" multiple class="w-full" name="destination governorates" v-on:input="data.qualifier.destination_governorates ? changeValueDestinationGov() : ''" v-model="data.qualifier.destination_governorates" :placeholder="$t('Destination Governorates')" label="name" :options="governorates"/>
            <span class="text-danger text-sm" v-show="errors.has('destination governorates')">{{ errors.first('destination governorates') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-8">
            <v-select v-validate="'required'" multiple class="w-full" name="service levels" v-model="data.qualifier.service_levels" v-on:input="data.qualifier.service_levels ? changeValueServiceLevels() : ''" :placeholder="$t('Service Level')" label="name" :options="serviceLevels"/>
            <span class="text-danger text-sm" v-show="errors.has('service levels')">{{ errors.first('service levels') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-8">
            <v-select v-validate="'required'" multiple class="w-full" name="package sizes" v-model="data.qualifier.package_sizes" :placeholder="$t('Package Size')" label="name" :options="packageSizes"/>
            <span class="text-danger text-sm" v-show="errors.has('package sizes')">{{ errors.first('package sizes') }}</span>
          </div>
          <div class="vx-col w-1/2 mt-8">
            <v-select v-validate="'required'" multiple class="w-full" name="pricing packages" v-model="data.qualifier.pricing_packages" v-on:input="data.qualifier.pricing_packages ? changeValuePricingPackages() : ''" :placeholder="$t('Pricing Package')" label="name" :options="pricingPackages"/>
            <span class="text-danger text-sm" v-show="errors.has('pricing packages')">{{ errors.first('pricing packages') }}</span>
          </div>
        </div>
        <div class="vx-row mt-10 mb-2">
          <div class="vx-col">
            <h4>{{$t('Threshold')}}</h4>
          </div>
        </div>
        <div class="vx-row mb-2">
          <div class="vx-col w-1/2 mt-5">
            <v-select v-validate="'required'" class="w-full" name="time window" v-model="data.threshold.time_window" :placeholder="$t('Time Window')" :options="timeWindows"/>
            <span class="text-danger text-sm" v-show="errors.has('time window')">{{ errors.first('time window') }}</span>
          </div>
          <div class="vx-col w-1/2">
            <vs-input v-validate="'required|numeric'" :label-placeholder="$t('Count') + '*'" name="count" v-model="data.threshold.count" class="w-full"/>
            <span class="text-danger text-sm" v-show="errors.has('count')">{{ errors.first('count') }}</span>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button v-if="type === 'Add' || type === 'Duplicate'" @click="addPromoCode" class="active-btn btn">{{ $t('Add') }}</button>
          <button v-else @click="updatePromoCode" class="active-btn btn">{{ $t('Save') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import i18nData from '../../../i18n/i18nData.js'
import { sendRequest }  from '../../../http/axios/requestHelper.js'
import Datepicker from 'vuejs-datepicker'
import common  from '@/assets/utils/common'
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['promoAndExceptionModal', 'data', 'type'],
  data () {
    return {
      disabledStartDates: {
        to: new Date() // Disable all dates up to specific date
      },
      disabledExpiryDates: {
        to: new Date() // Disable all dates up to specific date
      },
      timeWindows: ['This week', 'This month', 'Last week', 'Last month'],
      governorates: [],
      serviceLevels: [],
      packageSizes: [],
      pricingPackages: [],
      merchants: [],
      scopes: [
        {
          name: 'Private',
          value: 'private'
        },
        {
          name: 'Public',
          value: 'public'
        }
      ],
      subscriptions: [],
      typesValue: [
        {
          name: 'EGP',
          value: 'absolute'
        },
        {
          name: 'Percentage',
          value: 'percentage'
        }
      ],
      serviceTypes: ['delivery', 'return', 'exchange', 'cash_collection', 'rto', 'supplies_request'],
      promoCode: {}
    }
  },
  watch: {
    'data.limits.start_date' (val) {
      if (val) {
        this.disabledExpiryDates.to = new Date(this.data.limits.start_date)
      }
    },
    'data.unit' (val) {
      if (val && val.value === 'percentage' && this.data.value > 100) {
        this.data.value = 100
      }
    }
  },
  methods: {
    changeValueSourceGov () {
      if (this.data.qualifier.source_governorates.filter(item => item.name === 'Select All').length > 0) {
        this.data.qualifier.source_governorates = []
        this.data.qualifier.source_governorates.push({
          name: 'Select All'
        })
      }
    },
    changeValueServiceLevels () {
      if (this.data.qualifier.service_levels.filter(item => item.name === 'Select All').length > 0) {
        this.data.qualifier.service_levels = []
        this.data.qualifier.service_levels.push({
          name: 'Select All'
        })
      }
    },
    changeValuePricingPackages () {
      if (this.data.qualifier.pricing_packages.filter(item => item.name === 'Select All').length > 0) {
        this.data.qualifier.pricing_packages = []
        this.data.qualifier.pricing_packages.push({
          name: 'Select All'
        })
      }
    },
    changeValueDestinationGov () {
      if (this.data.qualifier.destination_governorates.filter(item => item.name === 'Select All').length > 0) {
        this.data.qualifier.destination_governorates = []
        this.data.qualifier.destination_governorates.push({
          name: 'Select All'
        })
      }
    },
    loadCode () {
      sendRequest(false, false, this, `${this.$route.name === 'growth-hacker-exception-discount' ? 'api/v1/discounts/exception-codes/' :  'api/v1/discounts/promo-codes/'}${this.data.id}/`, 'get', null, true,
        (response) => {
          this.promoCode = response.data
        }
      )
    },
    loadPackageSizes () {
      sendRequest(false, false, this, 'api/v1/package-sizes/', 'get', null, true,
        (response) => {
          this.packageSizes = response.data
        }
      )
    },
    loadPricingPackages () {
      sendRequest(false, false, this, 'api/v1/pricing-packages/?active=true', 'get', null, true,
        (response) => {
          this.pricingPackages = response.data.results
          this.pricingPackages.unshift({
            name: 'Select All'
          })
        }
      )
    },
    loadSubscriptions () {
      sendRequest(false, false, this, 'api/v1/subscriptions/', 'get', null, true,
        (response) => {
          this.subscriptions = response.data
        }
      )
    },
    loadServiceLevels () {
      sendRequest(false, false, this, 'api/v1/service-levels/', 'get', null, true,
        (response) => {
          this.serviceLevels = response.data
          this.serviceLevels.unshift({
            name: 'Select All'
          })
        }
      )
    },
    loadGovernorates () {
      const govPromise = common.loadGovernorates(this)
      govPromise.then((results) => {
        this.governorates = results.data
        this.governorates.unshift({
          name: 'Select All'
        })
      })
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadMerchantsSearch)
    },
    loadMerchantsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      if (this.searchVal) {
        this.loadMerchants()
      }
    },
    loadMerchants () {
      this.searchInProgress = true
      const query = `?name=${this.searchVal}`
      sendRequest(false, false, this, `api/v1/merchants/${query}`, 'get', null, true,
        (response) => {
          this.merchants = response.data.results
          this.searchedValue = this.searchVal
        }
      )
      this.searchInProgress = false
    },
    addPromoCode () {
      this.$validator.resume()
      this.$validator.validateAll().then(result => {
        if (result) {
          const promoCodeObj = {
            qualifier: {
              merchants: this.data.qualifier.merchants.length !== 0 ? this.data.qualifier.merchants.map(element => element.id) : ['all'],
              subscriptions: this.data.qualifier.subscriptions.map(element => element.id),
              service_levels: this.data.qualifier.service_levels.filter(item => item.name === 'Select All').length > 0 ? this.serviceLevels.filter(element => element.id).map(item => item.id) : this.data.qualifier.service_levels.map(element => element.id),
              service_types: this.data.qualifier.service_types.map(element => element),
              source_governorates: this.data.qualifier.source_governorates.filter(item => item.name === 'Select All').length > 0 ? this.governorates.filter(element => element.id).map(item => item.id) : this.data.qualifier.source_governorates.map(element => element.id),
              destination_governorates: this.data.qualifier.destination_governorates.filter(item => item.name === 'Select All').length > 0 ? this.governorates.filter(element => element.id).map(item => item.id) : this.data.qualifier.destination_governorates.map(element => element.id),
              package_sizes: this.data.qualifier.package_sizes.map(element => element.id),
              pricing_packages: this.data.qualifier.pricing_packages.filter(item => item.name === 'Select All').length > 0 ? this.pricingPackages.filter(element => element.id).map(item => item.id) : this.data.qualifier.pricing_packages.map(element => element.id)
            },
            limits: {
              total_usage_count: Number(this.data.limits.total_usage_count),
              per_merchant_usage_count: Number(this.data.limits.per_merchant_usage_count),
              total_cash_value: Number(this.data.limits.total_cash_value),
              per_merchant_cash_value: Number(this.data.limits.per_merchant_cash_value),
              start_date: new Date(this.data.limits.start_date).toISOString().split('T')[0],
              expiry_date: new Date(this.data.limits.expiry_date).toISOString().split('T')[0]
            },
            threshold: {
              count: Number(this.data.threshold.count),
              time_window: this.data.threshold.time_window.split(' ')[1] ? this.data.threshold.time_window.split(' ')[1] : this.data.threshold.time_window,
              span: this.data.threshold.time_window.split(' ')[0] === 'This' ? 0 : 1
            },
            code: this.data.code,
            description: this.data.description,
            unit: this.data.unit.value ? this.data.unit.value : this.data.unit,
            scope: this.data.scope.value,
            value: this.data.value,
            auto_apply: this.data.auto_apply
          }
          if (!this.isOnline) this.$emit('promoAndExceptionModal', false)
          sendRequest(false, false, this, `${this.$route.name === 'growth-hacker-exception-discount' ? 'api/v1/discounts/exception-codes/' :  'api/v1/discounts/promo-codes/'}`, 'post', promoCodeObj, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale][this.$route.name === 'growth-hacker-exception-discount' ? 'Exception Discount' : 'Promo Code'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.closeModal()
            }
          )
        }
      })
    },
    updatePromoCode () {
      this.$validator.resume()
      this.$validator.validateAll().then(result => {
        if (result) {
          const promoCodeObj = {
            qualifier: {
              merchants: this.data.qualifier.merchants ? this.data.qualifier.merchants.map(element => element.id) : ['all'],
              subscriptions: this.data.qualifier.subscriptions.map(element => element.id),
              service_levels: this.data.qualifier.service_levels.filter(item => item.name === 'Select All').length > 0 ? this.serviceLevels.filter(element => element.id).map(item => item.id) : this.data.qualifier.service_levels.map(element => element.id),
              service_types: this.data.qualifier.service_types.map(element => element),
              source_governorates: this.data.qualifier.source_governorates.filter(item => item.name === 'Select All').length > 0 ? this.governorates.filter(element => element.id).map(item => item.id) : this.data.qualifier.source_governorates.map(element => element.id),
              destination_governorates: this.data.qualifier.destination_governorates.filter(item => item.name === 'Select All').length > 0 ? this.governorates.filter(element => element.id).map(item => item.id) : this.data.qualifier.destination_governorates.map(element => element.id),
              package_sizes: this.data.qualifier.package_sizes.map(element => element.id),
              pricing_packages: this.data.qualifier.pricing_packages.filter(item => item.name === 'Select All').length > 0 ? this.pricingPackages.filter(element => element.id).map(item => item.id) : this.data.qualifier.pricing_packages.map(element => element.id)
            },
            limits: {
              total_usage_count: Number(this.data.limits.total_usage_count),
              per_merchant_usage_count: Number(this.data.limits.per_merchant_usage_count),
              total_cash_value: Number(this.data.limits.total_cash_value),
              per_merchant_cash_value: Number(this.data.limits.per_merchant_cash_value),
              start_date: new Date(this.data.limits.start_date).toISOString().split('T')[0],
              expiry_date: new Date(this.data.limits.expiry_date).toISOString().split('T')[0]
            },
            threshold: {
              count: Number(this.data.threshold.count),
              time_window: this.data.threshold.time_window.split(' ')[1],
              span: this.data.threshold.time_window.split(' ')[0] === 'This' ? 0 : 1
            },
            code: this.data.code,
            description: this.data.description,
            unit: this.data.unit.value,
            scope: this.data.scope.value,
            value: this.data.value,
            auto_apply: this.data.auto_apply
          }
          if (!this.isOnline) this.$emit('promoAndExceptionModal', false)
          sendRequest(false, false, this, `${this.$route.name === 'growth-hacker-exception-discount' ? 'api/v1/discounts/exception-codes/' :  'api/v1/discounts/promo-codes/'}${this.data.id}/`, 'patch', promoCodeObj, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale][this.$route.name === 'growth-hacker-exception-discount' ? 'Exception Discount' : 'Promo Code'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.closeModal()
            }
          )
        }
      })
    },
    closeModal () {
      this.$validator.pause()
      this.$emit('promoAndExceptionModal', false)
      this.$emit('loadData')
    }
  },
  components: {
    ShipbluPrompt,
    vSelect,
    Datepicker
  },
  created () {
    this.loadSubscriptions()
    this.loadGovernorates()
    this.loadPackageSizes()
    this.loadServiceLevels()
    this.loadPricingPackages()
  }
}
</script>
<style>
.vue-form-wizard .wizard-card-footer .wizard-footer-left {
  display: none !important;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss"
</style>
